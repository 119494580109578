
.body {
    text-align: left;
    margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: normal;
  }
  
  .title {
    /* background-color:darkseagreen; */
    padding: 16px;
  }
  
  .form {
    /* background-color:darkkhaki; */
    padding: 8px;
  }
  
  .help {
    /* background-color: bisque; */
    padding: 16px;
  }
  
  .helpIcon {
    margin-right: 8px;
  }
  
  table {
    margin-top: 32px;
  }
  
  table, th, td {
    border: 1px solid black;
    border-collapse: collapse;  
  }
  
  .input {
    margin: 8px;
    margin-top: 16px;
  }
  
  .titleCount {
    float: right;
    color:crimson;
  }
  
  .disclaimer {
    /* height: 12px; */
    background-color:gold;
    text-align: center;
    padding: 4px;
    font-style: italic;
  }